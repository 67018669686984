<template>
    <div v-if="loadedContracts">
        <!--    <b-alert show variant="light" class="alert alert-elevate">-->
        <!--      <div class="alert-icon">-->
        <!--        <i class="flaticon-warning kt-font-brand"></i>-->
        <!--      </div>-->
        <!--      <div class="alert-text">-->
        <!--        <b>Data tables</b> The <code>v-data-table</code> component is used for-->
        <!--        displaying tabular data. Features include sorting, searching,-->
        <!--        pagination, inline-editing, header tooltips, and row selection.-->
        <!--        <a-->
        <!--          class="kt-link kt-link&#45;&#45;brand kt-font-bold"-->
        <!--          href="https://vuetifyjs.com/en/components/data-tables"-->
        <!--          target="_blank"-->
        <!--        >-->
        <!--          See documentation.-->
        <!--        </a>-->
        <!--      </div>-->
        <!--    </b-alert>-->

        <div>
            <div class="row">
                <div class="col-md-8">
                    <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }"
                    >
                        <template v-slot:body>

                            <span class="topcardtitle">
                              Filtri e ricerca
                            </span>

                            <b-button-toolbar
                                    aria-label="Toolbar with button groups and input groups"
                            >

                                <b-button-group size="sm" class="mr-1" >
                                    <span style="    display: block;padding: 9px 3px 0 0;">Filtra:</span>

                                    <!--                  <b-button :to="{'name':'document-edit'}" class="mr-2 btn-primary">Nuovo documento</b-button>-->
                                    <!--<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>-->
                                    <b-form-select
                                            class="mb-1 mr-sm-1 mb-sm-0"
                                            v-model="filter.contract"
                                            :options="filteroptions.contract"
                                            id="inline-form-custom-select-pref"
                                            required
                                            @change="reloadList()"
                                    >
                                        <template v-slot:first>
                                            <option :value="null">Documento</option>
                                        </template>
                                    </b-form-select>

                                    <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            v-model="filter.statussign"
                                            :options="filteroptions.statussign"
                                            id="inline-form-custom-select-pref"
                                            required
                                            @change="reloadList()"
                                    >
                                        <template v-slot:first>
                                            <option :value="null">Stato firma</option>
                                        </template>
                                    </b-form-select>

                                    <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            v-model="filter.statusinstantcall"
                                            :options="filteroptions.statusinstantcall"
                                            id="inline-form-custom-select-pref"
                                            required
                                            @change="reloadList()"
                                    >
                                        <template v-slot:first>
                                            <option :value="null">Stato instant call</option>
                                        </template>
                                    </b-form-select>

                                    <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            v-model="filter.statuscrm"
                                            :options="filteroptions.statuscrm"
                                            id="inline-form-custom-select-pref"
                                            required
                                            @change="reloadList()"
                                    >
                                        <template v-slot:first>
                                            <option :value="null">Stato crm</option>
                                        </template>
                                    </b-form-select>

                                  <div class="col-2">
                                    <v-text-field style="max-width: 120px;" class="ml-3 textfilt textfiltup"
                                                  type="number"
                                                  v-model="searchid"
                                                  label="ID"
                                                  single-line
                                                  hide-details
                                                  v-on:input="deReloadList()"
                                    ></v-text-field>
                                  </div>

                                </b-button-group>


                            </b-button-toolbar>


                          <div class="row">
                            <div class="col-9">
                              <div style="width:320px;float:left;">
                                <vc-date-picker v-model="range" is-range :input-debounce="500"
                                                style="    align-self: flex-end;">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <div class="flex justify-center items-center" style=" margin-top: 20px;">
                                      Data:
                                      <input style="max-width: 114px;"
                                             placeholder="Inizio"
                                             :value="inputValue.start"
                                             v-on="inputEvents.start"
                                             class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                      />
                                      <input style="max-width: 114px;"
                                             placeholder="Fine"
                                             :value="inputValue.end"
                                             v-on="inputEvents.end"
                                             class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                      />
                                    </div>
                                  </template>
                                </vc-date-picker>
                              </div>
                              <v-text-field
                                  v-model="search"
                                  class="textfilt"
                                  append-icon="search"
                                  label="Ricerca libera"
                                  single-line
                                  hide-details
                                  v-on:input="deReloadList()"

                              ></v-text-field>
                            </div>

<!--                            <div class="col-3">-->
<!--       -->
<!--                            </div>-->
                            <div class="col-2"  style="margin-left: auto">
                              <button @click="resetFilters()" class="btn btn-secondary" style="margin-top: 20px;float: right;padding: 3px 10px;">
                                <v-icon
                                        small
                                        class="mr-2 vaction"

                                >
                                  mdi-close-circle-outline
                                </v-icon>
                                  <small>Reset</small>
                              </button>
<!--                              <button class="btn">-->
<!--                              <v-icon v-if="!isDraft" v-permission="'contract.fill'"-->
<!--                                      small-->
<!--                                      class="mr-2 vaction"-->
<!--                                      @click=""-->
<!--                              >-->
<!--                                mdi-database-search-->
<!--                              </v-icon>-->
<!--                              </button>-->

                            </div>
                          </div>


                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                </div>
                <div class="col-md-4">
                    <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }"
                    >
                        <template v-slot:body>

                            <span class="topcardtitle">
                              Export tracciato fornitore
                            </span>

                            <b-button-toolbar
                                    aria-label="Toolbar with button groups and input groups"
                            >


                                <b-button-group size="sm" class="ml-auto"  v-if="showDownloadCSVButton">
                                    <vc-date-picker v-model="rangeExport" is-range
                                                    class="" style="    margin-right: 10px;">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="flex justify-center items-center">
                                                Data:
                                                <input style="max-width: 110px;"
                                                       :value="inputValue.start"
                                                       v-on="inputEvents.start"
                                                       placeholder="Inizio"
                                                       class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                                />
                                                <input style="max-width: 110px;"
                                                       :value="inputValue.end"
                                                       v-on="inputEvents.end"
                                                       placeholder="Fine"
                                                       class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                                />
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                    <b-button  v-on:click="downloadCSV()" class="mr-3" variant="outline-primary">Scarica CSV</b-button>
                                </b-button-group>

                            </b-button-toolbar>
                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                  <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }"
                    >
                        <template v-slot:body>

                            <span class="topcardtitle">
                              Scarica lista filtrata
                            </span>

                            <b-button-toolbar
                                    aria-label="Toolbar with button groups and input groups"
                            >

                              <b-button-group size="sm" class="ml-auto" >
                                    <b-button v-on:click="frontendExport('csv')" class="mr-3" variant="outline-primary">Scarica CSV</b-button>
                                    <b-button v-on:click="frontendExport('xlsx')" class="mr-3" variant="outline-primary">Scarica Excel</b-button>
                                </b-button-group>

                            </b-button-toolbar>
                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                </div>

            </div>
        </div>
        <div v-permission="'contract.view'">
            <div class="row">
                <div class="col-md-12">
                    <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }" v-bind:class="{ 'kt-spinner-f': loading }"
                    >
                        <template v-slot:body>

                            <v-card>
<!--                                <v-card-title>-->
<!--                                    Elenco-->
<!--                                </v-card-title>-->
                                <v-data-table
                                        v-model="selected"
                                        :headers="headers"
                                        :items="items"
                                        :single-select="singleSelect"
                                        :sort-by="pagination.sortBy"
                                        :sort-desc="pagination.sortDesc"
                                        multi-sortNO
                                        item-key="id"
                                        show-selectNO
                                        class="elevation-1 table-force-width"
                                        :search="search"
                                        :footer-props="footerProps"
                                        :server-items-length="pagination.totalNumberOfItems"
                                        @update:page="paginate"
                                        @update:sort-by="sortBy"
                                        @update:sort-desc="sortDesc"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <span>{{item.status}}</span>
                                    </template>
                                    <template v-slot:item.created_at="{ item }">
                                        <span>{{item.created_at | formatDate}}</span>
                                    </template>
                                    <!--                  <template v-slot:item.updated_at="{ item }">-->
                                    <!--                    <span>{{item.updated_at | formatDate}}</span>-->
                                    <!--                  </template>-->
                                    <!--                                    <template v-slot:top>
                                                                            <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                                                        </template>-->
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon v-if="!isDraft" v-permission="'contract.fill'"
                                                small
                                                class="mr-2 vaction"
                                                @click="viewSubmission(item)"
                                        >
                                            mdi-eye
                                        </v-icon>
                                        <v-icon v-if="isDraft"  v-permission="'contract.fill'"
                                                small
                                                class="mr-2 vaction"
                                                @click="viewSubmission(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <a v-if="!isDraft && item.status === 'SIGNED'" class="vactiona"
                                           v-bind:href="item.downloadUrl" target="_blank">
                                            <v-icon v-permission="'contract.fill'"
                                                    small
                                                    class="mr-2 vaction"
                                            >
                                              mdi-download
                                            </v-icon>
                                        </a>
                                        <a style="cursor: pointer" v-if="!isDraft && item.status === 'SIGNED' && showDownloadCSVSingle(item.contract_configuration_id)"   v-on:click="downloadCSV(item.id)" class="vactiona"
                                           target="_blank">
                                            <v-icon v-permission="'contract.fill'"
                                                    small
                                                    class="mr-2 vaction"
                                            >
                                              mdi-file-excel
                                            </v-icon>
                                        </a>
                                      <div v-if="isDraft" class="inlineblock"><click-confirm>
                                        <v-icon  v-permission="'contract.fill'"
                                                 small
                                                 @click="deleteDraft(item)"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </click-confirm></div>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                    <p v-if="this.search">Attenzione: la ricerca libera agisce solo sui risultati visualizzati.</p>
                </div>

            </div>
        </div>

    </div>
</template>
<style>
    .table-force-width.v-data-table__wrapper table{
        max-width: 100%;
        table-layout: fixed;
    }
    .table-force-width.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .table-force-width.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .table-force-width.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    .table-force-width.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    .table-force-width.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
    .table-force-width.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        padding: 0 8px;
    }
    .textfilt{
      &.textfiltup{
        max-width: 100px;
        border: 1px solid #e2e5ec;
        margin: 0 auto;
        padding: 0;
      }
      .v-text-field__slot{
        height:35px;
      }
      label{
        left:10px !important;
        font-size: 13px;
      }
      input{
        padding: 8px 10px;
        font-size: 13px;
      }
    }
</style>
<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import ApiService from "@/common/api.service";
    import JwtService from "@/common/jwt.service";
    import {API_URL} from "@/common/config";
    import moment from 'moment';
    import UserService from "../../../common/user.service";
    // import ExcellentExport from 'excellentexport';
    // import Excellentexport from "excellentexport/dist/excellentexport";

    export default {
        data() {
            return {
                loading: false,
                singleSelect: false,
                isDraft:false,
                showAgentColumns:false,
                showRandomColumn:false,
                showDownloadCSVButton:false,
                selected: [],
                search: '',
                searchid: '',
                contractNames:{},
                loadedContracts:false,
                headers: null,
                range: {
                    start: null,
                    end: null
                },
                rangeExport: {
                    start: null,
                    end: null
                },
                pagination: {
                    //descending: !!this.$route.query.desc,
                    //sortBy: this.$route.query.orderby || 'name',
                    rowsPerPage: 100,
                    //listSize: [10, 25, 50, 100],
                    listSize: [100],
                    page: 1,
                    totalNumberOfItems: 0,
                    sortBy: ['id'],
                    sortDesc: [true],
                },
                footerProps: {'items-per-page-options': [100]},
                filteroptions: {
                    contract: [],
                    statussign:[
                      {
                        value:'SIGNED',
                        text:'SIGNED',
                      },
                      {
                        value:'SENT',
                        text:'SENT',
                      },
                      {
                        value:'REJECTED',
                        text:'REJECTED',
                      }
                    ],
                    statusinstantcall:[
                      {
                        value:'OK',
                        text:'OK',
                      },
                      {
                        value:'NOK',
                        text:'NOK',
                      },
                      {
                        value:'READY',
                        text:'READY',
                      },
                      {
                        value:'SENT',
                        text:'SENT',
                      },
                      {
                        value:'FAILED',
                        text:'FAILED',
                      },
                      {
                        value:'QC',
                        text:'QC',
                      },
                      {
                        value:'IGNORED',
                        text:'IGNORED',
                      },
                    ],
                    statuscrm:[
                      {
                        value:'READY',
                        text:'READY',
                      },
                      {
                        value:'IGNORED',
                        text:'IGNORED',
                      },
                      {
                        value:'FAILED',
                        text:'FAILED',
                      },
                      {
                        value:'SENT',
                        text:'SENT',
                      },
                      {
                        value:'NON INSERITO',
                        text:'NON INSERITO',
                      },
                      {
                        value:'INSERITO CON ATTIVATORE',
                        text:'INSERITO CON ATTIVATORE',
                      },
                      {
                        value:'RESPINTO',
                        text:'RESPINTO',
                      },
                      {
                        value:'RESPINTO DEFINITIVO',
                        text:'RESPINTO DEFINITIVO',
                      },
                      {
                        value:'INSERITO MANUALMENTE',
                        text:'INSERITO MANUALMENTE',
                      },
                      {
                        value:'IN GESTIONE CONTRATTO DI DESTINAZIONE',
                        text:'IN GESTIONE CONTRATTO DI DESTINAZIONE',
                      },
                      {
                        value:'VERIFICA DATI',
                        text:'VERIFICA DATI',
                      },
                      {
                        value:'IN GESTIONE',
                        text:'IN GESTIONE',
                      }
                    ]
                },
                filter: {},
                items: [],
            }
        },
        watch: {
            range: function () {
                this.reloadList();
            }
        },
        components: {
            KTPortlet
        },
        mounted() {



            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Documenti", route: "/document"},
                {title: "Storico invii", route: "history"}
            ]);

        },

        created() {
            this.showAgentColumns = this.shouldShowAgentColumns();
            this.showRandomColumn = this.shouldShowRandomColumn();
            this.isDraft = this.$route.name === 'document-history-drafts';
            this.resetFilters();

            this.loadOptions();
        },

        methods: {
            resetFilters(){
              this.filter = {
                contract: null,
                statussign: null,
                statusinstantcall: null,
                statuscrm: null
              };
              if(this.isDraft) {
                this.range.start = moment().subtract(1, 'months').toDate();
                this.range.end = moment().toDate();
              }else{
                this.range.start = null;
                this.range.end = null;
              }
              this.searchid = '';
              this.search = '';
              this.reloadList();
            },
            shouldShowAgentColumns() {
                return (this.$store.getters.currentUser.is_organization_admin || (this.$store.getters.currentUser.groups && this.$store.getters.currentUser.groups.length &&  this.$store.getters.currentUser.groups[0].is_admin));
                // return (this.$store.getters.currentUser.is_organization_admin || this.$store.getters.currentUser.is_admin); // || ![3,5,6].includes(this.$store.getters.currentUser.organization_id));
            },
            shouldShowRandomColumn() {
                return (window.__env && window.__env.allowedOrganizationsAlwaysShowRandomColumnHistory ? window.__env.allowedOrganizationsAlwaysShowRandomColumnHistory : [3,5,6]).includes(this.$store.getters.currentUser.organization_id);
            },
            loadOptions() {
                ApiService.get('contracts')
                    .then(({data}) => {
                        data.rows.forEach((x) => {
                            this.contractNames[parseInt(x.id)] = x.label;
                            this.filteroptions.contract.push({value: x.id, text: x.id + ' - ' + x.label});
                        });

                        this.loadedContracts = true;
                        this.initialize();
                    })
                    .catch(({response}) => {
                        //context.commit(SET_ERROR, response.data.errors);
                    });
            },
            paginate(val) {
                this.pagination.page = val;
                this.reloadList();
            },
            sortBy(val) {
                this.pagination.sortBy = val;
                this.reloadList();
            },
            sortDesc(val) {
                this.pagination.sortDesc = val;
                this.reloadList();
            },
            deReloadList() {
                if (window.search_timeout) clearTimeout(window.search_timeout);
                var that = this;
                window.search_timeout = setTimeout(function () {
                    that.reloadList();
                }, 800);
            },
            getListParameters(){
              const params = {
                page: this.pagination.page,
              };
              // if(UtilService.isNumeric()){
              //
              // }
              if (this.searchid) {
                params.id = parseInt(this.searchid);
              }
              if (this.range.start) {
                params.date_from = moment(this.range.start).format('YYYY-MM-DD');
              }
              if (this.range.end) {
                params.date_to = moment(this.range.end).format('YYYY-MM-DD');
              }
              if(this.filter.contract){
                params.contract_configuration_id = this.filter.contract;
              }
              if(this.filter.statussign){
                params.status_sign = this.filter.statussign;
              }
              if(this.filter.statusinstantcall){
                params.status_instant_call = this.filter.statusinstantcall;
              }
              if(this.filter.statuscrm){
                params.status_crm = this.filter.statuscrm;
              }
              // if(this.search){
              //   params.search = this.search;
              // }
              if(this.pagination.sortBy&&this.pagination.sortBy.length){
                params.order_by = this.pagination.sortBy[0];
              }
              if(this.pagination.sortDesc&&this.pagination.sortDesc.length){
                params.order_desc = this.pagination.sortDesc[0]?'desc':'asc';
              }
              return params;
            },
            filterListItems(items){
              return items.filter((x) => {
                if (this.filter.contract && this.filter.contract != x.contract_configuration_id) { // This will be removed later
                  return false;
                }

                // Try to get data from submission
                let holderData = [];
                let additionalInfo = this.isDraft ? JSON.parse(x.data).data : JSON.parse(x.additional_info);
                if (additionalInfo && additionalInfo.submission) {
                  if (additionalInfo.submission.ragione_sociale) {
                    holderData.push(additionalInfo.submission.ragione_sociale);
                  } else {
                    if (additionalInfo.submission.cognome) {
                      holderData.push(additionalInfo.submission.cognome);
                    }
                    if (additionalInfo.submission.nome) {
                      holderData.push(additionalInfo.submission.nome);
                    }
                  }
                }
                x._holder = holderData.join(' ');

                // Add more info if draft
                if(this.isDraft){
                  if(!x.email && additionalInfo.submission && additionalInfo.submission.email_intestatario){
                    x.email = additionalInfo.submission.email_intestatario;
                  }
                  if(!x.cellulare && additionalInfo.submission && additionalInfo.submission.cellulare_intestatario){
                    x.phone = additionalInfo.submission.cellulare_intestatario;
                  }
                }


                // Text filter
                if (this.search && (!x.id || x.id != this.search) && (!x._holder || x._holder.toLowerCase().indexOf(this.search.toLowerCase()) === -1) && (!x.email || x.email.toLowerCase().indexOf(this.search.toLowerCase()) === -1) && (!x.phone || x.phone.indexOf(this.search) === -1) && (!x.random_code || x.random_code.toLowerCase().indexOf(this.search.toLowerCase()) === -1) && (!x.codice_agente || x.codice_agente.toLowerCase().indexOf(this.search.toLowerCase()) === -1)) {
                  return false;
                }


                x.downloadUrl = API_URL + '/submissions/' + x.id + '?jwt=' + JwtService.getToken() + '&pdf=1';

                x.documentname = this.contractNames[parseInt(x.contract_configuration_id)];
                // console.log(this.contractNames,x.documentname);
                // x.documentname = this.contractNames[x.contract_template_id];
                return true;
              });
            },
            reloadList() {
                // console.log('reloadinglist', this.range);
                this.loading = true;
                const params = this.getListParameters();


                const callbackList = this.isDraft ?
                        ApiService.get('drafts', '?' + (new URLSearchParams(params)).toString()) :
                        ApiService.get('submissions', '?' + (new URLSearchParams(params)).toString());

                callbackList
                    .then(({data}) => {

                        this.pagination.totalNumberOfItems = data.count;
                        this.items = this.filterListItems(data.rows);
                        this.loading = false;
                    })
                    .catch(({response}) => {
                        //context.commit(SET_ERROR, response.data.errors);
                    });
            },
            getHeaders(){
              let headers = this.isDraft ?
                  [
                    {text: 'ID', value: 'id'},
                    this.showAgentColumns ? null : {text: 'Documento', value: 'documentname', sortable: false},
                    {text: 'Intestatario', value: '_holder', sortable: false},
                    {text: 'Email', value: 'email', sortable: false},
                    {text: 'Phone', value: 'phone', sortable: false},
                    this.showAgentColumns ? {text: 'User', value: 'codice_agente', sortable: false} : null,
                    {text: 'Created At', value: 'created_at'}
                  ]:[
                    {text: 'ID', value: 'id'},
                    this.showAgentColumns ? null : {text: 'Documento', value: 'documentname', sortable: false},
                    this.showRandomColumn ? {text: 'Random', value: 'random_code', sortable: false} : null,
                    {text: 'Intestatario', value: '_holder', sortable: false},
                    {text: 'Email', value: 'email', sortable: false},
                    {text: 'Phone', value: 'phone', sortable: false},
                    {text: 'Firma Status', value: 'status', sortable: false},
                    {text: 'Inst. Call Status', value: 'instant_call_status', sortable: false},
                    {text: 'CRM Status', value: 'eva_status', sortable: false},
                    // {text: 'Updated At', value: 'updated_at'},
                    {text: 'Agente', value: 'user_email', sortable: false},
                    this.showAgentColumns ? {text: 'User', value: 'codice_agente', sortable: false} : null,
                    this.showAgentColumns ? {text: 'Agenzia', value: 'codice_subagenzia', sortable: false} : null,
                    // {text: 'Agenzia', value: 'codice_subagenzia'},
                    {text: 'Created At', value: 'created_at'},
                  ];
                headers = headers.filter(x => !!x);
                return headers;
            },
            initialize() {


                this.headers = this.getHeaders();


                if (this.$route.query.filter_contract) {
                    this.filter.contract = this.$route.query.filter_contract;
                }

                // if(UserService.hasPermission(['contract.edit'])){
                this.headers.push({text: 'Actions', value: 'actions', sortable: false, width: '150px'});
                // }
                this.reloadList();

                this.showDownloadCSVButton = this.showDownloadCSV();

            },
            frontendExport: async function(format){
              // const excellentExport = require('excellentexport/src/excellentexport.ts');

              const a = document.createElement('a')
              // a.download = 'export' + '.xlsx'
              a.href = '#'

              let headers = this.getHeaders();
              const excellentExport = window.ExcellentExport;
              this.loading = true;
              // https://github.com/jmaister/excellentexport
              const options =  {
                anchor:a, // String (optional)
                // anchor: String or HTML Element,
                format: format, //'xlsx' or 'xls' or 'csv',
                filename: 'export',
                // rtl: Use Right-to-left characters, boolean (optional)
              };
              // Iterate to get all data until no data left
              let data = [];
              let page = 1;

              let dataheader = [];
              headers.forEach((h) => {
                dataheader.push([h.text]);
              });
              data.push(dataheader);

              while(true){
                const params = this.getListParameters();
                params.page = page++;

                let datat = this.isDraft ?
                    await ApiService.get('drafts', '?' + (new URLSearchParams(params)).toString()) :
                    await ApiService.get('submissions', '?' + (new URLSearchParams(params)).toString());
                const count = datat.data.rows.length;
                datat = this.filterListItems(datat.data.rows);
                // Use headers to get the data by header value
                datat = datat.map((x) => {
                  let row = [];
                  headers.forEach((h) => {
                    if(!x[h.value]){
                      row.push('');
                      return;
                    }
                    if(h.value=='created_at'){
                      row.push(moment(x[h.value]).format('DD/MM/YYYY HH:mm'));
                      return;
                    }
                    row.push(''+x[h.value]);
                  });
                  return row;
                });
                data = data.concat(datat);
                if(!count) {
                  break;
                }
              }
              // console.log(data);


              const sheets = [
                {
                  name: 'export', // Sheet name
                  from: {
                    // table: String/Element, // Table ID or table element
                    array: data // Array with the data. Array where each element is a row. Every row is an array of the cells.
                    // array: [
                    //     ['a','b','c'],
                    //     ['a','b','f'],
                    // ] // Array with the data. Array where each element is a row. Every row is an array of the cells.
                  },
                  // removeColumns: [...], // Array of column indexes (from 0)
                  // filterRowFn: function(row) {return true}, // Function to decide which rows are returned
                  // fixValue: function(value, row, column) {return fixedValue} // Function to fix values, receiving value, row num, column num
                  // fixArray: function(array) {return array} // Function to manipulate the whole data array
                  // rtl: Use Right-to-left characters, boolean (optional)
                  // formats: [...] // Array of formats for each column. See formats below.
                },
              ];
              // const excellentExport = require('excellentexport/src/excellentexport.ts');
              excellentExport.convert(options, sheets);


              // document.body.appendChild(a);
              a.click()
              // a.remove();

              this.loading= false;


            },

            // editSubmission (item) {
            //   this.$router.push({ name: "document-submission-edit", 'params':{'id':item.id} });
            // },
            viewSubmission(item) {
                this.$router.push({
                    name: this.isDraft ? "document-draft-view" : "document-submission-view",
                    'params': {'id': item.contract_configuration_id, 'sid': item.id}
                });
            },

            deleteDraft(item) {
                ApiService.delete('drafts/'+item.id,{'id':item.id}).then(() => this.reloadList());
            },
            showDownloadCSV(){
                let allowed = window.__env && window.__env.allowedOrganizationsDownloadCsv ? window.__env.allowedOrganizationsDownloadCsv : [1,7,8,9,10,11,12,13,14,16,19,22];
                return UserService.isSuperDebugUser() || (this.$store.getters.currentUser.is_organization_admin && allowed.includes(this.$store.getters.currentUser.organization_id));
            },
            showDownloadCSVSingle(contractConfigurationId){
                let allowed = window.__env && window.__env.allowedContractConfigurationIdsDownloadSingleCsv ? window.__env.allowedContractConfigurationIdsDownloadSingleCsv : [];
                return (UserService.isSuperDebugUser() || (this.$store.getters.currentUser.is_organization_admin)) && allowed.includes(contractConfigurationId);
            },
            downloadCSV(id) {
                const params = {};
                params.jwt = JwtService.getToken();
                if(!id){
                  if (this.rangeExport.start) {
                      params.date_from = moment(this.rangeExport.start).format('YYYY-MM-DD');
                  }
                  if (this.rangeExport.end) {
                      params.date_to = moment(this.rangeExport.end).format('YYYY-MM-DD');
                  }
                  if (this.filter.contract) {
                      params.contract_configuration_id = this.filter.contract;
                  }
                }

                window.open(
                    API_URL+'/exports/export-csv'+ (id ? '/'+id : '') + '?' +(new URLSearchParams(params)),
                    '_blank' // <- This is what makes it open in a new window.
                );
            }


        },
    };
</script>
